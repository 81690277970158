<template>
  <div>
    <div class="vx-row">
      <div class="vx-col w-full">
        <!-- iconos para tipos de credito -->
        <div class="flex flex-wrap items-center flex-grow justify-start">  
          <div v-for="img in getLoanTypeIcon('all')" :key="img.id" class="mr-5">
            <img slot="image" style="width: 40px;" :src="img.url" class="center" alt="banner" />
            <small>{{img.filename}}</small>
          </div>
        </div> 
        <h5 class="mt-5 mb-2">Consideraciones</h5>
        <p>- El plazo de los proyectos semilla se presenta en años.</p>
        <p>- La calificación crediticia corresponde al nivel de riesgo de cada proyecto: riesgo mínimo <b>(A)</b>,
          riesgo
          muy bajo <b>(B)</b>, riesgo bajo <b>(C)</b>, riesgo moderado <b>(D)</b>, riesgo medio alto <b>(E)</b>.</p>
        <p>- Si deseas saber más sobre cómo calificamos los proyectos da clic <router-link
            class="bold inline-link-primary text-primary" to="#"
            @click.native="goToProjectsQualificationBlog">aquí.</router-link>
        </p>
        <p>- Los montos económicos mencionados se expresan en moneda nacional.</p>
      </div>
    </div>

    <!-- Vista Tabla -->
    <div v-infinite-scroll="getProjectsList" infinite-scroll-disabled="loading" infinite-scroll-distance="10" id="data-list-thumb-view" class="data-list-container">
      <!-- <vs-table :sst="true" @sort="handleSort" class="mt-4" :data="projects" v-model="currentSelection" @selected="onRowSelected" noDataText="No hay registros" > -->
      <vs-table :sst="true" @sort="handleSort" class="mt-4" :data="projects" noDataText="No hay registros" >
        <template slot="thead">
          <vs-th class="bold" sort-key="id">ID</vs-th>
          <vs-th class="bold" sort-key="name">Nombre</vs-th>
          <vs-th class="bold" sort-key="loan_type">Tipo de crédito</vs-th>
          <vs-th class="bold" sort-key="qualification">Calif.</vs-th>
          <vs-th class="bold" sort-key="percent">Tasa</vs-th>
          <vs-th class="bold" sort-key="statusSort">Estado</vs-th>
        </template>

        <template slot-scope="{data}">
          <tbody>
          <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
            <vs-td>
              <p class="font-medium">{{ tr.id }}</p>
            </vs-td>
            <vs-td>
              <p class="pl-3 bold">
                <!-- <router-link class="bold text-dark ml-1 inline-link" :to="{name: 'proyectoInversionista', params: {id: tr.id}}"> -->
                  {{tr.name}}
                <!-- </router-link> -->
              </p>
            </vs-td>
            <vs-td>
              <img :src="getLoanTypeIcon(tr.loan_type)" style="width: 40px" alt="" class="mr-5">
            </vs-td>
            <vs-td class="calif-container">
              <p class="font-medium"> <span class="qualification-circle" :class="`bg-${qualificationCode(tr.qualification)}`">{{ tr.qualification }}</span> </p>
            </vs-td>
            <vs-td>
              <p class="font-medium">{{ tr.percentFormat }}</p>
            </vs-td>
            <vs-td>
              <p class="font-medium"> <span class="status-chip" :class="tr.statusClass">{{ tr.statusFormat }}</span> </p>
            </vs-td>
          </vs-tr>
          </tbody>
        </template>
      </vs-table>
      <h5 v-if="lastPageReached" class="text-center mt-base">No hay más resultados para mostrar</h5>
    </div>
  </div>
  </template>

  <script>
  import formatHelper from '@mixins/formatHelper';
  import currencyHelper from '@mixins/currencyHelper';
  import qualificationsHelper from '@mixins/qualificationsHelper';
  import creditStatusesHelper from "@mixins/creditStatusesHelper";
  import projectStatusesHelper from "@mixins/projectStatusesHelper";
  import InvestedProjectCard from "@components/investor/invested/InvestedProjectCard";
  import VxPagination from "@/vx-components/vx-pagination/VxPagination";
  import ProjectStatusEnum from "@/modules/enums/ProjectStatusEnum";

  export default {
     mixins: [formatHelper, currencyHelper, qualificationsHelper, creditStatusesHelper, projectStatusesHelper, ProjectStatusEnum],
     components: { InvestedProjectCard, VxPagination },
     data(){
        return {
          allDataProjectsSearch: [],
          currentPage: 0,
          loading: false,
          isFirstTime: true,
          projects: [],
          filteredProjects: [],
          isMounted: false,
          currentSelection: null,
          mobileScreen: 576,
          statusFilterSelected: [
            'up_to_date',
            'delayed',
            'expired',
            'on_recovery',
            'recovered'
          ],
          statusOptions:[
            {
              value: 'investment_commitment',
              text: 'Compromiso de inversion'
            },
            {
              value: 'up_to_date',
              text: 'Al corriente'
            },
            {
              value: 'delayed',
              text: 'Retrasado'
            },
            {
              value: 'expired',
              text: 'Vencido'
            },
            {
              value: 'settled',
              text: 'Pagado'
            },
            {
              value: 'on_recovery',
              text: 'Quebrantado'
            },
            {
              value: 'recovered',
              text: 'Recuperado'
            },
            {
              value: 'refunded',
              text: 'Reembolsado'
            }
          ],
        }
     },

     async beforeMount(){
       this.isMounted = false
       this.showLoading(true)
       await this.getProjectsList();
       this.isMounted = true
       this.showLoading(false)
     },
    computed: {
      isMobileScreen () {
        return this.$store.state.windowWidth <= this.mobileScreen
      },
      lastPageReached () {
        return this.currentPage == this.allDataProjectsSearch.last_page;
      },
    },
    methods: {
      handleSort(key, active) {
        if(active === 'asc'){
          this.projects.sort(function(a, b) {
            if(a[key] < b[key]){
              return -1
            }
            if(a[key] > b[key]){
              return 1
            }
            return 0;
          });
        }
        else if(active === 'desc') {
          this.projects.sort(function(a, b) {
            if(a[key] < b[key]){
              return 1
            }
            if(a[key] > b[key]){
              return -1
            }
            return 0;
          });
        }
        else {
          this.projects.sort(function(a, b) {
            return b.id - a.id;
          });
        }
        this.currentx = 1;
      },
      async getProjectsList(){
        if (this.lastPageReached) {
          return;
        }
        this.showLoading(true);
        this.loading = true;
         try {
            let url = `api/secondary-market-projects?page=${this.currentPage+1}`;
            const res = await axios.post(url, {filterStatuses: this.statusFilterSelected});
            this.allDataProjectsSearch = res.data; 
            this.setProjects(res.data.data);
            this.currentPage++;
            this.showLoading(false);
            this.loading = false;
         }
         catch (error) {
           this.warn(error);
           this.showLoading(false);
           this.loading = false;
         }
      },
      mergedStatusName(project){
        // si el estatus del proyecto es 5, 6 o 10, se muestra el nombre del estatus de proyecto
        if(project.project_status === ProjectStatusEnum.FUNDING || project.project_status === ProjectStatusEnum.SIGNING_CONTRACTS || project.project_status === ProjectStatusEnum.REFUND){
          return this.projectStatusName(project.project_status);
        }
        // si el estatus del proyecto es 7, se combinan los nombres de ambos
        else if(project.project_status === ProjectStatusEnum.INSTALLING) {
          let nn = this.projectStatusName(project.project_status);
          nn += ", ";
          nn += this.creditStatusName(project.asset_status, project.status);
          return nn;
        }
        else {
          return this.creditStatusName(project.asset_status, project.status);
        }
      },
      mergedStatusOrder(project){
        if(project.project_status < ProjectStatusEnum.DELIVERED ){
          return project.project_status;
        }
        else {
          return this.creditStatusOrder(project.asset_status, project.status);
        }
      },
      mergedCreditStatusClass(project){
        if(project.project_status < ProjectStatusEnum.DELIVERED ){
          return this.projecStatusClass(project.project_status);
        }
        else {
          return this.creditStatusClass(project.asset_status, project.status);
        }
      },
      setProjects(projects){
        projects.forEach(f => {
          f.name = f.alias.trim();
          f.statusFormat = this.mergedStatusName(f);
          f.statusSort = this.mergedStatusOrder(f);
          f.statusClass = this.mergedCreditStatusClass(f);
          f.percent = this.currencyFormat(f.annual_interest *100, 2);
          f.percentFormat = `${f.percent}%`;
          this.projects.push(f);
        });
        // projects.sort(function (a,b){
        //   return b.id - a.id;
        // });

        return projects;
      },
      async onRowSelected(tr){
        //await this.goToProjectDetail(tr.id);
      },
      async goToProjectDetail(id){
        await this.$router.push({name: 'proyectoInversionista', params: {id: id}});
      }
    }
  }
  </script>

