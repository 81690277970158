<template>
  <div v-if="isMounted">
    <big-title>
      Invertir.
    </big-title>

    <!-- PANEL DE CUMPLIMIENTO -->
    <compliance-banner class="mb-4" @loadedRequirements="loadedRequirements" />

    <!-- si el inversionista ya esta en el MS -->
    <template v-if="InvestorRegisteredOnSecondaryMarket">
      <vx-card v-if="showManualInvestment">
        <vs-tabs v-model="activeTab" class="tab-action-btn-fill-container profile-tabs">
          <vs-tab label="Mercado secundario">
            <div class="vx-row">
              <div class="vx-col xl:w-2/3 w-full mt-base mb-3">
                <h1>Portafolio RG</h1>
                <label class="bold">
                  Tu dinero se diversificará automáticamente en las posiciones que existan a la venta en el mercado
                  secundario.
                </label>
                <!-- <div class="vx-row mt-3">
                  <div class="vx-col">
                    <div class="flex flex-wrap items-center justify-start">
                      <p><b>Saldo disponible:</b> {{ explicitMoneyFormat(roleGeneralData.balance) }}</p>
                    </div>
                  </div>
                </div> -->
                <div class="vx-row mt-5">
                  <div class="vx-col">
                    <div class="flex flex-wrap items-center justify-start mb-4">
                      <vs-input class="sm-investment-input" type="text" v-model.lazy="amountSecondaryMarketInvestment"
                        v-money="amountSecondaryMarketInvestment !== null || amountSecondaryMarketInvestment === 0 || amountSecondaryMarketInvestment === '$0' ? money : null"
                        name="amountSecondaryMarketInvestment" v-validate="'required'" autocomplete="off" />
                      <vs-button v-if="canInvest" id="invest_sm_btn" class="ml-3" color="primary"
                        @click.stop="startSmInvestment()">Invertir</vs-button>
                      <!-- <span><router-link class="bold ml-3 inline-link-primary text-dark" to=""
                          @click.native="showSimulatorPopUp = true">Simular</router-link></span> -->
                    </div>
                  </div>
                </div>
                <div class="vx-row mt-base mb-base">
                  <div class="vx-col w-full">
                    <div class="vx-row">
                      <div class="vx-col">
                        <h3>Rendimiento</h3>
                        <h4>{{ decimalPercentFormat(secondaryMarketData.marketYield, 0) }}</h4>
                      </div>
                      <div class="vx-col">
                        <h3>Cartera Total</h3>
                        <h4>{{ moneyFormat(secondaryMarketData.debitBalance) }}</h4>
                      </div>
                      <div class="vx-col">
                        <h3>Proyectos</h3>
                        <h4>{{ secondaryMarketData.totalProjects }}
                        <feather-icon v-if="!showSecondaryMarketProjectsPopUp" icon="ChevronDownIcon" @click.stop="showSecondaryMarketProjectsPopUp = true" svgClasses="w-6 h-6"
                            style="color: #006238;" />
                        <feather-icon v-if="showSecondaryMarketProjectsPopUp" icon="ChevronUpIcon" @click.stop="showSecondaryMarketProjectsPopUp = false" svgClasses="w-6 h-6"
                            style="color: #006238;" />
                        </h4>
                      </div>
                    </div>
                    <div class="vx-col mt-base">
                      <div class="flex">
                        <feather-icon icon="InfoIcon" svgClasses="w-5 h-5 text-dark" class="mr-2" />
                        <small class="bold mt-1">
                          Rendimiento anual promedio estimado de los últimos 12 meses antes de
                        comisiones. 
                        </small>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="vx-row" v-if="showSecondaryMarketProjectsPopUp">
              <div class="vx-col w-full">
                <projects-list-on-secondary-market/>
              </div>
            </div>
          </vs-tab>
          <vs-tab label="Proyectos en fondeo">
            <oportunities-list />
          </vs-tab>
        </vs-tabs>
      </vx-card>
      <vx-card v-else>
        <div class="vx-row mb-base">
          <!-- invierte con impacto -->
          <div class="vx-col xl:w-1/3 w-full p-5">
            <div class="vx-row">
              <h3 class="mb-4 ml-5">Invierte con impacto</h3>
            </div>
            <div class="vx-row">
              <div class="vx-col w-full mt-base mb-3">
                <label class="bold">
                  Tu dinero se <a
                    href="https://soporte.redgirasol.com/support/solutions/articles/48001246946--c%C3%B3mo-funciona-el-algoritmo-de-la-inversion-automatica-"
                    class="underline bold text-dark" target="blank">diversificará automáticamente</a> en los proyectos
                  de la cartera de RedGirasol para minimizar riesgos.
                </label>
                <div class="vx-row mt-3">
                  <div class="vx-col">
                    <div class="flex flex-wrap items-center justify-start">
                      <p><b>Saldo disponible:</b> {{ explicitMoneyFormat(roleGeneralData.balance) }}</p>
                      <span><router-link class="bold ml-3 inline-link-primary text-dark" to=""
                          @click.native="showPayFundsPopUp()">Abonar</router-link></span>
                    </div>
                  </div>
                </div>
                <div class="vx-row mt-5">
                  <div class="vx-col">
                    <div class="flex flex-wrap items-center justify-start mb-4">
                      <vs-input class="sm-investment-input" type="text" v-model.lazy="amountSecondaryMarketInvestment"
                        v-money="amountSecondaryMarketInvestment !== null || amountSecondaryMarketInvestment === 0 || amountSecondaryMarketInvestment === '$0' ? money : null"
                        name="amountSecondaryMarketInvestment" v-validate="'required'" autocomplete="off" />
                      <vs-button v-if="canInvest" id="invest_sm_btn" class="ml-3" color="primary"
                        @click.stop="startSmInvestment()">Invertir</vs-button>
                    </div>
                    <span class="mt-5"><router-link class="bold inline-link-primary text-dark" to=""
                        @click.native="showManualInvestment = true">Inversión manual</router-link></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- a donde va mi inversion -->
          <div class="vx-col xl:w-1/3 w-full p-5">
            <div class="vx-row">
              <h3 class="mb-4 ml-5">¿A dónde va mi inversión?</h3>
            </div>
            <div class="vx-row" style="background-color: #E7FFBB; border-radius: 20px; height: 100%;">
              <div class="vx-col w-full mt-base mb-3">
                <h3 class="bold ml-5">Mercado secundario</h3>
                <div class="vx-row mt-base">
                  <div class="vx-col xl:w-1/3 w-full text-center">
                    <p>Rendimiento</p>
                    <h5>{{ decimalPercentFormat(secondaryMarketData.marketYield, 0) }}</h5>
                  </div>
                  <div class="vx-col xl:w-1/3 w-full text-center">
                    <p>Cartera Total</p>
                    <h5>{{ moneyFormat(secondaryMarketData.debitBalance) }}</h5>
                  </div>
                  <div class="vx-col xl:w-1/3 w-full text-center">
                    <p>Proyectos M.S</p>
                    <h5>{{ secondaryMarketData.totalProjects }}</h5>
                  </div>
                  <div class="vx-col mt-base">
                    <small class="bold">*Rendimiento anual promedio estimado de los últimos 12 meses antes de
                      comisiones.</small>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- proyectos en fondeo -->
          <div class="vx-col xl:w-1/3 w-full p-5">
            <div class="vx-row mt-base mb-2"></div>
            <div class="vx-row" style="background-color: #F5F5F5; border-radius: 20px; height: 100%;">
              <div class="vx-col w-full mt-base mb-3">
                <h3 class="bold ml-5">Proyectos en fondeo</h3>
                <div class="vx-row mt-base">
                  <div class="vx-col xl:w-1/3 w-full text-center">
                    <p>Monto a financiar</p>
                    <h5>{{ explicitMoneyFormat(remainingFundingData.remaining) }}</h5>
                  </div>
                  <div class="vx-col xl:w-1/3 w-full text-center">
                    <p>Tipos de Credito</p>
                    <div class="flex flex-wrap items-center justify-center mb-4">
                      <img slot="image" v-for="loanType in remainingFundingData.loanTypes" :key="loanType" style="width: 30px" class="ml-2"
                        :src="getLoanTypeIcon(loanType)" alt="banner" />
                    </div>
                  </div>
                  <div class="vx-col xl:w-1/3 w-full text-center">
                    <p>Tasas</p>
                    <h5>{{ decimalPercentFormat(remainingFundingData.minRate, 2) }} - {{ decimalPercentFormat(remainingFundingData.maxRate, 2) }}</h5>
                  </div>
                  <div class="vx-col mt-base">
                    <small class="bold">*Rendimiento anual promedio estimado de los últimos 12 meses antes de
                      comisiones.</small>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </vx-card>
    </template>
    <!-- si el inversionista no esta en el MS -->
    <template v-else>
      <vx-card>
        <vs-tabs v-model="activeTab" class="tab-action-btn-fill-container profile-tabs">
          <vs-tab label="Mercado secundario">
            <div class="flex flex-wrap items-center justify-between mt-4">
              <div>
                <h2 class="mb-base">Únete al mercado secundario y mejora tu inversión</h2>
                <secondary-market-requirements @on-signed="onSignedSmContract" />
              </div>
              <img slot="image" src="https://cdn.redgirasol.com/investors/home/forma-parte.png" alt="banner" />
            </div>
          </vs-tab>
          <vs-tab label="Proyectos en fondeo">
            <oportunities-list />
          </vs-tab>
        </vs-tabs>
      </vx-card>
    </template>

    <!-- <vx-card class="mt-5">
      <div class="vx-row">
        <div class="vx-col w-full">
          iconos para tipos de credito
          <div class="flex flex-wrap items-center flex-grow justify-start">  
            <div v-for="img in getLoanTypeIcon('all')" :key="img.id" class="mr-5">
              <img slot="image" style="width: 40px;" :src="img.url" class="center" alt="banner" />
              <small>{{img.filename}}</small>
            </div>
          </div> 
          <h5 class="mt-5 mb-2">Consideraciones</h5>
          <p>- El plazo de los proyectos semilla se presenta en años.</p>
          <p>- La calificación crediticia corresponde al nivel de riesgo de cada proyecto: riesgo mínimo <b>(A)</b>,
            riesgo
            muy bajo <b>(B)</b>, riesgo bajo <b>(C)</b>, riesgo moderado <b>(D)</b>, riesgo medio alto <b>(E)</b>.</p>
          <p>- Si deseas saber más sobre cómo calificamos los proyectos da clic <router-link
              class="bold inline-link-primary text-primary" to="#"
              @click.native="goToProjectsQualificationBlog">aquí.</router-link>
          </p>
          <p>- Los montos económicos mencionados se expresan en moneda nacional.</p>
        </div>
      </div>
    </vx-card> -->

    <!-- Popup para ver datos para abonar fondos -->
    <vs-popup v-if="popUpPayFunds" :active.sync="popUpPayFunds" title="Abona a tu cuenta"
      class="header-primary">
      <stp-account-info-minimal class="p-5" :stpClabe="stpClabe" />
    </vs-popup>


    <!-- Popup de exito al firmar el contrato de ms -->
    <vs-popup v-if="smContractSignedSuccessPopUp" :active.sync="smContractSignedSuccessPopUp"
      class="mid-popup no-header">
      <div class="vx-row">
        <div class="vx-col w-full">
          <img src='https://cdn.redgirasol.com/investors/home/success_secondary_market_bnner.png' class="responsive">
          <div class="text-center p-5">
            <h3 class="mb-2">Pronto tu inversión contará con más liquidez y diversificación que nunca. Te
              notificaremos por correo cuando suceda el cambio.</h3>
          </div>
        </div>
      </div>
      <div class="flex flex-wrap justify-center">
        <vs-button color="dark" @click.stop="smContractSignedSuccessPopUp = false">Finalizar</vs-button>
      </div>
    </vs-popup>

    <!-- Popup de simulador inversion manual ms -->
    <vs-popup v-if="showSimulatorPopUp" :active.sync="showSimulatorPopUp" class="mid-popup header-primary"
      title="Simulador">
      <div class="p-10">
        <div class="vx-row">
          <div class="vx-col md:w-1/2 w-full">
            <h3 class="mb-5">Calcula tu inversión</h3>
            <label>Monto que deseas invertir</label>
            <vs-input class="sm-investment-input mb-5" type="text" v-model.lazy="amountSecondaryMarketInvestmentSimulator"
              v-money="amountSecondaryMarketInvestmentSimulator !== null || amountSecondaryMarketInvestmentSimulator === 0 || amountSecondaryMarketInvestmentSimulator === '$0' ? money : null"
              name="amountSecondaryMarketInvestmentSimulator" v-validate="'required'" autocomplete="off" />

            <label>Cuánto valdría en</label>
            <vs-select placeholder="Seleccione" v-model="simulatorInvestmentSmPeriod">
              <vs-select-item :key="index" :value="item.value" :text="item.text"
                v-for="(item, index) in statusPeriodOptions" />
            </vs-select>


            <vs-button id="invest_sm_btn" class="mt-base" color="primary"
              @click.stop="simulateSmInvestment()">Calcular</vs-button>
          </div>
          <div class="vx-col md:w-1/2 w-full">
            <div class="vx-row mt-5" style="background-color: white; border-radius: 20px;">
              <div class="vx-col mt-5 mb-5 w-full">
                <label class="mt-5">Tu inversión de <b>{{ amountSecondaryMarketInvestmentSimulator }}</b> tendrá un valor de:</label>
                <div class="flex flex-wrap items-center justify-start mt-5">
                  <h3 class="text-primary">{{ moneyFormat(currentSimulationData.totalInvestment)}}*</h3>
                </div>
              </div>
            </div>
            <div class="vx-row" style="background-color: white; border-radius: 20px;">
              <div class="vx-col mb-5 w-full">
                <label class="mt-5">El valor de tu <b>portafolio</b> con esta nueva inversión será de:</label>
                <div class="flex flex-wrap items-center justify-start mt-5">
                  <h3 class="text-primary">{{ moneyFormat(currentSimulationData.totalPortfolio)}}*</h3>
                </div>
              </div>
            </div>
            <div class="vx-col mt-2">
              <div class="flex">
                <feather-icon icon="InfoIcon" svgClasses="w-5 h-5 text-dark" class="mr-2" />
                <small class="mt-1">
                  Estimación en función de resultados históricos globales de RedGirasol. 
                </small>
              </div>
            </div>
          </div>
        </div>
      </div>
    </vs-popup>
    <!-- Componente para obtener la geolocalización -->
    <rg-geolocator ref="rgGeolocator" />
  </div>
</template>

<script>
import { mapState } from "vuex";
import { VMoney } from 'v-money'
import formatHelper from '@mixins/formatHelper';
import currencyHelper from '@mixins/currencyHelper';
import ComplianceBanner from "@components/compliance/ComplianceBanner";
import userComplianceHelper from "@/helpers/userComplianceHelper";
import investorInfoHelper from "@/helpers/investorInfoHelper";
import TwoFactorAuth from "@components/2fa/TwoFactorAuth";
import auth2faHelper from "@mixins/auth2faHelper";
import OportunitiesList from "@components/investor/invested/OportunitiesList";
import ProjectsListOnSecondaryMarket from "@components/investor/invested/ProjectsListOnSecondaryMarket";
import StpAccountInfoMinimal from "@components/stp/StpAccountInfoMinimal";
import stpHelper from "@mixins/stpHelper";
import SecondaryMarketRequirements from "@components/investor/secondary-market/SecondaryMarketRequirements";
import VueApexCharts from 'vue-apexcharts';


export default {
  mixins: [formatHelper, currencyHelper, userComplianceHelper, investorInfoHelper, auth2faHelper, stpHelper],
  directives: { money: VMoney },
  components: {
    ComplianceBanner,
    TwoFactorAuth,
    OportunitiesList,
    ProjectsListOnSecondaryMarket,
    StpAccountInfoMinimal,
    SecondaryMarketRequirements,
    VueApexCharts
  },
  data() {
    return {
      showSecondaryMarketProjectsPopUp: false,
      showSimulatorPopUp: false,
      showManualInvestment: false,
      smContractSignedSuccessPopUp: false,
      popUpPayFunds: false,
      stpClabe: null,
      user_person_type: null,
      amountSecondaryMarketInvestment: null,
      amountSecondaryMarketInvestmentSimulator: null,
      activeTab: 0,
      isMounted: false,
      investmentPopupActive: false,
      money: {
        decimal: '.',
        thousands: ',',
        prefix: '$',
        suffix: '',
        precision: 0,
        masked: false
      },
      factorEnabled: false,
      mainComplianceRequirements: 0,
      statusPeriodOptions: [
        {
          value: '10',
          text: '10 años'
        },
        {
          value: '5',
          text: '5 años'
        },
        {
          value: '1',
          text: '1 año'
        }
      ],
      simulatorInvestmentSmPeriod: 10,
      
      secondaryMarketData: {
        marketYield: 0,
        debitBalance: 0,
        totalProjects: 0
      },

      remainingFundingData: {
        remaining: 0,
        minRate: 0,
        maxRate: 0,
        loanTypes: []
      },

      currentSimulationData: {
        totalInvestment: null,
        totalPortfolio: null,
      },


    }
  },
  async mounted() {
    await this.getSecondaryMarketRgInfo();
    await this.getRemainingFundingData();
  },
  async beforeMount() {
    this.user_person_type = parseInt(this.UserPersonType);
    this.isMounted = false
    this.showLoading(true)
    this.factorEnabled = await this.getUser2FAStatus(this.UserId);
    this.isMounted = true
    this.showLoading(false)
  },
  computed: {
    ...mapState("auth", ["roleGeneralData"]),
    canInvest() {
      return this.mainComplianceRequirements === 0;
    },
    isMexicanPM() {
      return this.user_person_type === 0;
    },
    isMexicanPF() {
      return this.user_person_type === 1;
    },
    isInvestmentPopupActive: {
      get() {
        return this.investmentPopupActive;
      },
      set(val) {
        this.investmentPopupActive = val;
      }
    },
  },
  methods: {
    async getRemainingFundingData() {
      try {
        await axios.get('/api/remaining-funding-data').then((response) => {
          this.remainingFundingData.remaining = response.data.remaining;
          this.remainingFundingData.minRate = response.data.min_rate;
          this.remainingFundingData.maxRate = response.data.max_rate;
          this.remainingFundingData.loanTypes = response.data.loan_types;
        });
      }
      catch (e) {

      }
    },
    async getSecondaryMarketRgInfo() {
      try {
        await axios.get('/api/secondary-market-rg-stats').then((response) => {
          this.secondaryMarketData.marketYield = response.data.yield;
          this.secondaryMarketData.debitBalance = response.data.debit_balance;
          this.secondaryMarketData.totalProjects = response.data.total_projects;
        });
      }
      catch (e) {

      }
    },
    // async check2fa() {
    //   if (!this.factorEnabled) {
    //     this.isInvestmentPopupActive = false;
    //     this.$vs.dialog({
    //       color: "warning",
    //       type: "confirm",
    //       title: "Autenticación necesaria",
    //       text: "Para realizar esta operación es necesario habilitar tu Segundo Factor de Autenticación.",
    //       acceptText: "Habilitar",
    //       cancelText: 'Cancelar',
    //       accept: this.goTo2FaPage,
    //     });
    //     return;
    //   }
    //   this.request2faCode();
    // },
    // async goTo2FaPage() {
    //   await this.$router.push({ name: 'auth2FaInversionista' });
    // },
    // request2faCode() {
    //   // se pide la geolocalizacion para la operacion
    //   this.$refs.rgGeolocator.askForGeolocation();
    //   this.isInvestmentPopupActive = false;
    //   this.$store.commit("TOGGLE_IS_2FA_POPUP_ACTIVE", true);
    // },
    // async onAuth(code) {
    //   await this.confirmInvestment();
    // },
    // async onAuthFailed(code) {
    // },
    // async confirmInvestment() {

    // },
    async startSmInvestment(){
      let balance = this.roleGeneralData.balance;
      const newInv = this.unMoneyFormat(this.amountSecondaryMarketInvestment);
      if (newInv > balance) {
        this.confirmationDialog(this.goToFunding, 'Saldo insuficiente', 'No cuentas con saldo suficiente para realizar la inversión.', 'Abonar');
        return;
      }

      let payload = {
        investor_id: this.InvestorId,
        amount: newInv,
        // geolocation: this.CurrentGeolocationData
      }
      this.showLoading(true, 'En proceso de inversión.');
      try {
        const res = await axios.post('/api/v2/investor/start-sm-investment', payload);
        await this.getSecondaryMarketRgInfo();
        await this.getRemainingFundingData();
        this.successNotifDialog('Operación exitosa', 'La inversión ha sido realizada exitosamente.');
      }
      catch (e) {
        if(e.response && e.response.data){
          this.errorNotifDialog('La inversión no se ha podido ejecutar', e.response.data.error);
        }
        else {
          this.errorNotifDialog('Ha ocurrido un error', 'Por favor intenta más tarde');
        }
      }
      this.showLoading(false);
    },
    async simulateSmInvestment(){
      const newInv = this.unMoneyFormat(this.amountSecondaryMarketInvestmentSimulator);
      let payload = {
        investor_id: this.InvestorId,
        amount: newInv,
        years: this.simulatorInvestmentSmPeriod
        // geolocation: this.CurrentGeolocationData
      }
      this.showLoading(true, 'En proceso de inversión.');
      try {
        const response = await axios.post('/api/v2/investor/sm-investment-simulate', payload);
        this.currentSimulationData = response.data;
      }
      catch (e) {
        this.errorNotifDialog('Ha ocurrido un error', 'Por favor intenta más tarde');
      }
      this.showLoading(false);
    },
    validateInvestmentAmount(proj, amount) {
      if (amount === null) {
        return false;
      }
      var maxAmount = proj.maximum;
      if (proj.maximum >= proj.remaining) {
        maxAmount = proj.remaining;
      }
      return amount >= proj.minimum && amount <= maxAmount;
    },
    async goToMyProjects() {
      await this.$router.push({ name: 'misProyectosInversionista' });
    },
    loadedRequirements(count) {
      this.mainComplianceRequirements = count;
    },
    async showPayFundsPopUp() {
      if (this.isMexicanPF || this.isMexicanPM) {
        this.stpClabe = await this.getInvestorAccountStpClabe(this.InvestorId);
      }
      this.popUpPayFunds = true;
    },
    async onSignedSmContract() {
      this.smContractSignedSuccessPopUp = true;
      await this.onUserInfoUpdated();
    },
    async goToFunding() {
      await this.$router.push({ name: 'abonarFondos' });
    }
  }
}
</script>

<style>
div.vs-dialog-danger {
  z-index: 100010 !important;
}

div.vs-dialog-success {
  z-index: 100010 !important;
}
</style>
