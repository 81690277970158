<template>
  <div>
    <!-- PANEL DE CUMPLIMIENTO -->
    <compliance-banner class="mb-4" @loadedRequirements="loadedRequirements" :show-banner="false" />


    <vx-card title="¡Descubre nuevas oportunidades de inversión!">
      <div class="vx-row mb-base">
        <div class="vx-col xxl:w-3/4 w-full">
          <p>
            A continuación te presentamos todos los proyectos semilla que se encuentran actualmente en la etapa de
            fondeo.
            No pierdas la oportunidad y participa en la transición energética de México. ¡Para todos brilla el sol!
          </p>
          <p class="mt-5">
            No olvides que con el fin de fomentar la diversificación y reducir los riesgos de tus inversiones, todos los
            proyectos cuentan
            con un mínimo y máximo de inversión.<strong> Consulta estos montos a un lado del botón de Simular e
              Invertir.</strong>
          </p>
        </div>
      </div>
      <div class="vx-row">
        <div class="vx-col w-full">
          <!-- iconos para tipos de credito -->
          <div class="flex flex-wrap items-center flex-grow justify-start">  
            <div v-for="img in getLoanTypeIcon('all')" :key="img.id" class="mr-5">
              <img slot="image" style="width: 40px;" :src="img.url" class="center" alt="banner" />
              <small>{{img.filename}}</small>
            </div>
          </div> 
          <h5 class="mt-5 mb-2">Consideraciones</h5>
          <p>- El plazo de los proyectos semilla se presenta en años.</p>
          <p>- La calificación crediticia corresponde al nivel de riesgo de cada proyecto: riesgo mínimo <b>(A)</b>,
            riesgo
            muy bajo <b>(B)</b>, riesgo bajo <b>(C)</b>, riesgo moderado <b>(D)</b>, riesgo medio alto <b>(E)</b>.</p>
          <p>- Si deseas saber más sobre cómo calificamos los proyectos da clic <router-link
              class="bold inline-link-primary text-primary" to="#"
              @click.native="goToProjectsQualificationBlog">aquí.</router-link>
          </p>
          <p>- Los montos económicos mencionados se expresan en moneda nacional.</p>
        </div>
      </div>
      <!--div class="vx-row mt-4 mb-6">
                 <div class="vx-col w-full">
                   <div class="flex flex-wrap items-center">
                     <vs-button @click="">Diversificar mi saldo</vs-button>
                   </div>
                 </div>
               </div-->
      <div id="data-list-thumb-view" class="data-list-container mt-4">
        <vs-table class="mt-5" ref="table" @selected="onRowSelected" :data="projects" pagination
          :max-items="itemsPerPage" noDataText="No hay proyectos disponibles">
          <template slot="thead">
            <vs-th class="bold" sort-key="name">Nombre</vs-th>
            <vs-th class="bold" sort-key="loan_type">TIPO DE CRÉDITO</vs-th>
            <vs-th class="bold" sort-key="qualification">Calif.</vs-th>
            <vs-th class="bold" sort-key="anual_interest">Tasa</vs-th>
            <vs-th class="bold" sort-key="years">Plazo</vs-th>
            <vs-th class="bold" sort-key="financed_amount">Monto a financiar</vs-th>
            <vs-th class="bold" sort-key="remaining">Faltantes</vs-th>
            <!-- <vs-th class="bold" sort-key="progressSort">Avance de fondeo</vs-th> -->
            <vs-th class="bold">Monto a invertir</vs-th>
            <vs-th></vs-th>
            <vs-th></vs-th>
          </template>

          <template slot-scope="{data}">
            <tbody>
              <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                <vs-td>
                  <p class="pl-3 bold">
                    <router-link class="bold text-dark ml-1 inline-link"
                      :to="{ name: 'oportunidadInversion', params: { id: tr.id } }">
                      {{ tr.name }}
                    </router-link>
                  </p>
                </vs-td>
                <vs-td>
                  <img :src="getLoanTypeIcon(tr.loan_type)" style="width: 40px" alt="" class="mr-5">
                </vs-td>
                <vs-td class="calif-container">
                  <p class="font-medium"> <span class="qualification-circle"
                      :class="`bg-${qualificationCode(tr.qualification)}`">{{ tr.qualification }}</span> </p>
                </vs-td>
                <vs-td>
                  <p class="font-medium">{{ tr.anual_interest }}</p>
                </vs-td>
                <vs-td>
                  <p class="font-medium">{{ tr.years }}</p>
                </vs-td>
                <vs-td>
                  <p class="font-medium">{{ tr.amountFormat }}</p>
                </vs-td>
                <vs-td>
                  <p class="font-medium">{{ tr.remainingFormat }}</p>
                  <vs-progress :percent="Number(tr.progress)" color="success" class="shadow-md" />
                </vs-td>
                <!-- <vs-td>
                               <div>
                                   <span>{{tr.progress}}%</span>
                                   <vs-progress :percent="Number(tr.progress)" color="success" class="shadow-md" />
                               </div>
                             </vs-td> -->

                <vs-td colspan="3" class="img-container pl-3">
                  <vs-input class="mt-2 investment-input" type="text" v-model.lazy="tr.newInvestment"
                    v-money="tr.newInvestment !== null || tr.newInvestment === 0 || tr.newInvestment === '$0' ? money : null"
                    name="amount" v-validate="'required'" :min="1" autocomplete="off"
                    :placeholder="tr.minPlaceholder" />
                  <vs-button id="run_simulation_btn" :data-projectid="tr.id" class="statement-btn" color="dark"
                    @click.stop="simulateInvestment(tr)">Simular</vs-button>
                  <vs-button v-if="canInvest && tr.maximum > 0" id="invest_btn" :data-projectid="tr.id"
                    class="statement-btn" :color="colors.green" @click.stop="startInvestment(tr)">Invertir</vs-button>
                  <vs-button v-if="!canInvest || tr.maximum <= 0" :disabled="true" class="statement-btn"
                    :color="colors.grayBtn">Invertir</vs-button>
                </vs-td>

              </vs-tr>
            </tbody>
          </template>
        </vs-table>
      </div>

      <!-- iconos para tipos de credito
      <div class="flex flex-wrap items-center flex-grow justify-start">  
        <div v-for="img in getLoanTypeIcon('all')" :key="img.id" class="mr-5">
          <img slot="image" style="width: 40px;" :src="img.url" class="center" alt="banner" />
          <small>{{img.filename}}</small>
        </div>
      </div>       -->
    </vx-card>

    <vs-popup :active.sync="isInstructionsPopupActive" title="Monto no válido">
      <div class="pt-2 p-4">
        <h3 class="title-primary">¿Estás list@ para crecer tu dinero?</h3>
        <p class="mt-4">
          Para realizar la simulación con el proyecto
          <span class="bold">{{ currentProjectName }}</span>,
          ingresa un monto mínimo de
          <span class="bold">{{ currentMinInvestment }}</span> o un monto máximo de
          <span class="bold">{{ currentMaxInvestmentSimulation }}</span> en el espacio correspondiente.
        </p>
      </div>
    </vs-popup>

    <vs-popup :active.sync="isInvalidInvestmentPopupActive" title="Monto no válido para inversión">
      <div class="pt-2 p-4">
        <h3 class="title-primary">¿Estás list@ para crecer tu dinero?</h3>
        <p class="mt-4">
          Para realizar una inversión en el proyecto
          <span class="bold">{{ currentProjectName }}</span>,
          ingresa un monto mínimo de
          <span class="bold">{{ currentMinInvestment }}</span> o un monto máximo de
          <span class="bold">{{ currentMaxInvestment }}</span> en el espacio correspondiente.
        </p>
      </div>
    </vs-popup>

    <vs-popup class="mid-popup header-primary" :active.sync="isInvestmentPopupActive"
      title="Confirmación de Inversión.">
      <div class="pt-1 p-4">
        <h4 class="mb-2">¡Ya estás a un clic de invertir con impacto!</h4>
        <p>Confirma tu inversión y disfruta de los beneficios de la energía limpia.</p>

        <div class="vx-row mt-5">
          <div class="vx-col lg:w-1/3 md:w-1/3 sm:w-1/2 w-full">
            <p class="mb-1 pl-1">Nombre del proyecto</p>
            <p class="info-border-inline mb-6">{{ currentProjectName }}</p>
          </div>
          <div class="vx-col lg:w-1/3 md:w-1/3 sm:w-1/2 w-full">
            <p class="mb-1 pl-1">Monto de inversión</p>
            <p class="info-border-inline mb-6">{{ explicitMoneyFormat(investmentData.amount) }}</p>
          </div>
          <div class="vx-col lg:w-1/3 md:w-1/3 sm:w-1/2 w-full">
            <p class="mb-1 pl-1">Bonos disponibles</p>
            <p class="info-border-inline mb-6">{{ explicitMoneyFormat(investmentData.availableBonus) }}</p>
          </div>
          <div class="vx-col lg:w-1/3 md:w-1/3 sm:w-1/2 w-full" v-if="investAllWithBonus">
            <p class="mb-1 pl-1">Bonos restantes</p>
            <p class="info-border-inline mb-6">{{ explicitMoneyFormat(investmentData.newBonus) }}</p>
          </div>
          <div class="vx-col lg:w-1/3 md:w-1/3 sm:w-1/2 w-full" v-if="!investAllWithBonus">
            <p class="mb-1 pl-1">Saldo a ser utilizado</p>
            <p class="info-border-inline mb-6">{{ explicitMoneyFormat(investmentData.balanceToUse) }}</p>
          </div>
          <div class="vx-col lg:w-1/3 md:w-1/3 sm:w-1/2 w-full" v-if="!investAllWithBonus">
            <p class="mb-1 pl-1">Saldo resultante</p>
            <p class="info-border-inline mb-6">{{ explicitMoneyFormat(investmentData.newBalance) }}</p>
          </div>
        </div>
        <div class="flex">
          <vs-button class="mt-4" @click="check2fa">Confirmar</vs-button>
          <vs-button type="border" class="ml-6 mt-4" @click="cancelInvestment">Cancelar</vs-button>
        </div>
      </div>
    </vs-popup>

    <vs-popup class="extended-popup header-primary" :active.sync="isSumulationViewActive"
      title="Simulación de inversión" v-if="isSumulationViewActive">
      <investment-simulation :initial-investment="initialSimulationInvestment" :current-project="currentProject"
        :current-project-name="currentProjectName" @on-simulation-updated="onSimulationUpdated" />
    </vs-popup>

    <vs-popup :active.sync="successDialogActive" title="Inversión exitosa." class="header-primary">
      <investment-success-dialog :current-project-id="currentProject.id" @on-action-1="goToInvestmentPlan"
        @on-action-2="finishInvestment" />
    </vs-popup>

    <!-- Componente para segundo factor -->
    <two-factor-auth :user-id="UserId" button-id="2fa_activated_btn" :current-project-id="currentProject.id"
      custom-indication-message="Por favor ingresa los 6 dígitos de tu Segundo Factor de Autenticación para realizar la inversión."
      @on-code-validated="onAuth" @on-code-invalid="onAuthFailed" />

    <!-- Componente para obtener la geolocalización -->
    <rg-geolocator ref="rgGeolocator" />
  </div>
</template>

<script>
import { mapState } from "vuex";
import { VMoney } from 'v-money'
import formatHelper from '@mixins/formatHelper';
import currencyHelper from '@mixins/currencyHelper';
import ComplianceBanner from "@components/compliance/ComplianceBanner";
import qualificationsHelper from '@mixins/qualificationsHelper';
import dateHelper from "@mixins/dateHelper";
import stpHelper from "@mixins/stpHelper";
import { investorColors } from "@mixins/investorColors";
import InvestmentSimulation from "@components/investor/opportunityTabs/InvestmentSimulation";
import InvestmentSuccessDialog from "@components/investor/opportunityTabs/InvestmentSuccessDialog";
import userComplianceHelper from "@/helpers/userComplianceHelper";
import userIdentityHelper from "@/helpers/userIdentityHelper";
import investorInfoHelper from "@/helpers/investorInfoHelper";
import TwoFactorAuth from "@components/2fa/TwoFactorAuth";
import auth2faHelper from "@mixins/auth2faHelper";
import InvestorPlanBanner from "@components/investor/plans/InvestorPlanBanner";

export default {
  mixins: [formatHelper, currencyHelper, qualificationsHelper, dateHelper, userComplianceHelper, userIdentityHelper, investorInfoHelper, stpHelper, auth2faHelper],
  directives: { money: VMoney },
  components: {
    InvestmentSimulation,
    InvestmentSuccessDialog,
    ComplianceBanner,
    TwoFactorAuth,
    InvestorPlanBanner
  },
  data() {
    return {
      amountSecondaryMarketInvestment: null,
      activeTab: 0,
      selected: [],
      projects: [],
      userData: {
        balance: null,
        bonus: null,
      },
      payments: [],
      itemsPerPage: 15,
      isMounted: false,
      aliasCurrency: null,
      instructionsPopupActive: false,
      invalidInvestmentPopupActive: false,
      investmentPopupActive: false,
      simulationViewActive: false,
      money: {
        decimal: '.',
        thousands: ',',
        prefix: '$',
        suffix: '',
        precision: 0,
        masked: false
      },
      currentProjectName: null,
      currentMinInvestment: null,
      currentMaxInvestment: null,
      currentMaxInvestmentSimulation: null,
      currentSimulationInvestment: null,
      currentProject: {},
      investAllWithBonus: false,
      currentSimulationData: {
        intereses: null,
        interesesTotal: null,
        meses: null,
        pagoMensualTotal: null,
        pagoTotal: null,
        porcentaje: null,
        recuperacion: null,
        recuperacionTotal: null,
      },

      investmentData: {
        amount: 0,
        availableBonus: 0,
        balanceToUse: 0,
        newBalance: 0,
        newBonus: 0,
      },

      factorEnabled: false,
      initialSimulationInvestment: 0,
      simulationPopupActive: false,
      successDialogActive: false,
      mainComplianceRequirements: 0,
    }
  },
  mounted() {
  },
  async beforeMount() {
    this.isMounted = false
    this.showLoading(true)
    await this.getOpportunities();
    this.factorEnabled = await this.getUser2FAStatus(this.UserId);
    this.isMounted = true
    this.showLoading(false)
  },
  computed: {
    ...mapState("auth", ["roleGeneralData"]),
    canInvest() {
      return this.mainComplianceRequirements === 0;
    },
    currentPage() {
      if (this.isMounted) {
        return this.$refs.table.currentx
      }
      return 0
    },
    paymentsPlanCurrentPage: {
      get: function () {
        if (this.isMounted && this.$refs.paymentsPlanTable) {
          return this.$refs.paymentsPlanTable.currentx;
        }
        return 0;
      },
      set: function (val) {
        this.$refs.paymentsPlanTable.currentx = val;
      }
    },
    colors() {
      return investorColors;
    },
    isInstructionsPopupActive: {
      get() {
        return this.instructionsPopupActive;
      },
      set(val) {
        this.instructionsPopupActive = val;
      }
    },
    isInvalidInvestmentPopupActive: {
      get() {
        return this.invalidInvestmentPopupActive;
      },
      set(val) {
        this.invalidInvestmentPopupActive = val;
      }
    },
    isSumulationViewActive: {
      get() {
        return this.$store.state.isSimulationPopupActive;
      },
      set(val) {
        this.$store.commit("TOGGLE_IS_SIMULATION_POPUP_ACTIVE", val);
      }
    },
    isInvestmentPopupActive: {
      get() {
        return this.investmentPopupActive;
      },
      set(val) {
        this.investmentPopupActive = val;
      }
    },
  },
  methods: {
    async getOpportunities() {
      try {
        const res = await axios.get('api/proyectos/oportunidades');
        this.projects = this.setAndOrderProjects(res.data.all);
        this.userData = res.data.user;
      }
      catch (e) {
        this.warn(e);
      }
    },
    setAndOrderProjects(projects) {
      // ordenamiento
      let topProjects = projects.filter(f => f.public_top === 1);
      let bottomProjects = projects.filter(f => f.public_top !== 1);
      topProjects.sort((a, b) => (a.public_date < b.public_date) ? -1 : 1);
      bottomProjects.sort((a, b) => (a.public_date < b.public_date) ? -1 : 1);
      let sortedProjects = [];
      topProjects.forEach(f => sortedProjects.push(f));
      bottomProjects.forEach(f => sortedProjects.push(f));

      // ajuste y calculos
      sortedProjects.forEach(f => {
        f.years = f.term / 12;
        f.amountFormat = this.explicitMoneyFormat(f.financed_amount, 2);
        f.remaining = f.financed_amount - f.invested;
        f.remainingFormat = this.explicitMoneyFormat(f.remaining, 0);
        f.progress = this.currencyFormat((f.invested / f.financed_amount) * 100, 2);
        f.progressSort = Math.round((f.invested / f.financed_amount) * 100);
        f.minFormat = this.explicitMoneyFormat(f.minimum, 0);
        if (f.maximum >= f.remaining) {
          f.maxFormat = this.explicitMoneyFormat(f.remaining, 0);
        }
        else {
          f.maxFormat = this.explicitMoneyFormat(f.maximum, 0);
        }
        f.minPlaceholder = `min. ${f.minFormat} max. ${f.maxFormat}`;
        f.newInvestment = null;
      });
      //
      return sortedProjects;
    },
    async simulateInvestment(proj) {
      this.setCurrentProjVars(proj);
      const newInvAmount = this.unMoneyFormat(proj.newInvestment);
      const valid = !newInvAmount ? false : (newInvAmount >= proj.minimum && newInvAmount <= proj.remaining);
      if (!valid) {
        this.isInstructionsPopupActive = true;
        return;
      }
      this.initialSimulationInvestment = newInvAmount;
      this.isSumulationViewActive = true;
    },
    onSimulationUpdated(value) {
      /*console.log("updated to " + value);
      this.currentProject.newInvestment = null;
      this.$nextTick(() => {
        console.log("it is to " + value);
        this.currentProject.newInvestment = value;
      });*/
    },
    async startInvestment(proj) {
      this.setCurrentProjVars(proj);
      const newInv = this.unMoneyFormat(proj.newInvestment);
      const valid = this.validateInvestmentAmount(proj, newInv);
      if (!valid) {
        this.isInvalidInvestmentPopupActive = true;
        return;
      }

      // calculo con bonus
      let balance = this.userData.balance;
      if (this.userData.bonus > 0) {
        balance += this.userData.bonus;
      }

      if (newInv > balance) {
        this.confirmationDialog(this.goToFunding, 'Saldo insuficiente', 'No cuentas con saldo suficiente para realizar la inversión.', 'Abonar');
        return;
      }
      this.setInvestmentVars(newInv);
      this.isInvestmentPopupActive = true;
    },
    async goToFunding() {
      await this.$router.push({ name: 'abonarFondos' });
    },
    setInvestmentVars(amount) {
      const currentBonus = this.userData.bonus;
      this.investmentData.amount = amount;
      this.investmentData.availableBonus = currentBonus;
      this.investAllWithBonus = false;

      // establecer bonus
      let balanceToUse = 0;
      let bonusToUse = 0;
      if (currentBonus > 0) {
        if (currentBonus >= amount) {
          this.investAllWithBonus = true;
          balanceToUse = 0;
          bonusToUse = amount;
        }
        else {
          bonusToUse = currentBonus;
          balanceToUse = amount - bonusToUse;
        }
      }
      else {
        balanceToUse = amount;
      }
      this.investmentData.balanceToUse = balanceToUse;
      this.investmentData.newBalance = this.userData.balance - balanceToUse;
      this.investmentData.newBonus = currentBonus - bonusToUse;
    },
    async check2fa() {
      if (!this.factorEnabled) {
        this.isInvestmentPopupActive = false;
        this.$vs.dialog({
          color: "warning",
          type: "confirm",
          title: "Autenticación necesaria",
          text: "Para realizar esta operación es necesario habilitar tu Segundo Factor de Autenticación.",
          acceptText: "Habilitar",
          cancelText: 'Cancelar',
          accept: this.goTo2FaPage,
        });
        return;
      }
      this.request2faCode();
    },
    async goTo2FaPage() {
      await this.$router.push({ name: 'auth2FaInversionista' });
    },
    request2faCode() {
      // se pide la geolocalizacion para la operacion
      this.$refs.rgGeolocator.askForGeolocation();
      this.isInvestmentPopupActive = false;
      this.$store.commit("TOGGLE_IS_2FA_POPUP_ACTIVE", true);
    },
    async onAuth(code) {
      await this.confirmInvestment();
    },
    async onAuthFailed(code) {
    },
    async confirmInvestment() {
      let payload = {
        investor_id: this.InvestorId,
        project_id: this.currentProject.id,
        amount: this.investmentData.amount,
        geolocation: this.CurrentGeolocationData
      }
      this.showLoading(true, 'En proceso de inversión.');
      try {
        this.isInvestmentPopupActive = false;
        const res = await axios.post('/api/v2/investor/createNewInvestment', payload);
        await this.onInvestorGeneralDataUpdated();
        await this.getOpportunities();
        this.factorEnabled = await this.getUser2FAStatus(this.UserId);
        this.successDialogActive = true;
      }
      catch (e) {
        this.investmentPopupActive = false;
        if (e.response && e.response.data) {
          this.errorNotifDialog('La inversión no se ha podido ejecutar', e.response.data.error);
        }
        else {
          this.errorNotifDialog('Ha ocurrido un error', 'Por favor intenta más tarde');
        }
      }
      this.showLoading(false);
    },
    async cancelInvestment() {
      this.isInvestmentPopupActive = false;
    },
    async getPaymentsInfo() {
      const updatedInv = this.unMoneyFormat(this.currentSimulationInvestment);
      const payload = { project_id: this.currentProject.id, investment: updatedInv, user_id: this.UserId };
      try {
        const response = await axios.post(`api/projects/simulation`, payload);
        this.payments = response.data;
      }
      catch (e) {
      }
    },
    setCurrentProjVars(proj) {
      this.currentProject = proj;
      this.currentProjectName = proj.name;
      this.currentMinInvestment = proj.minFormat;
      this.currentMaxInvestment = proj.maxFormat;
      this.currentMaxInvestmentSimulation = proj.remainingFormat;
      //this.currentSimulationInvestment = proj.newInvestment;
    },
    validateInvestmentAmount(proj, amount) {
      if (amount === null) {
        return false;
      }
      var maxAmount = proj.maximum;
      if (proj.maximum >= proj.remaining) {
        maxAmount = proj.remaining;
      }
      return amount >= proj.minimum && amount <= maxAmount;
    },
    finishInvestment() {
      this.successDialogActive = false;
    },
    async onRowSelected(tr) {
      //await this.$router.push({name: 'oportunidadInversion', params: {id: tr.id}});
    },
    async goToMyProjects() {
      await this.$router.push({ name: 'misProyectosInversionista' });
    },
    async goToInvestmentPlan() {
      this.successDialogActive = false;
      await this.$router.push({ name: 'planesInversion' });
    },
    getProgresColor(num) {
      if (num >= 50) return 'success'
      if (num < 50 && num >= 20) return 'warning'
      if (num < 20) return 'danger'
      return 'primary'
    },
    loadedRequirements(count) {
      this.mainComplianceRequirements = count;
    }
  }
}
</script>


<style lang="scss">

.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

#data-list-thumb-view {
  .vs-con-table {

    .vs-table--header {
      display: flex;
      flex-wrap: wrap-reverse;
      margin-left: 1.5rem;
      margin-right: 1.5rem;

      >span {
        display: flex;
        flex-grow: 1;
      }

      .vs-table--search {
        padding-top: 0;

        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          font-size: 1rem;

          &+i {
            left: 1rem;
          }

          &:focus+i {
            left: 1rem;
          }
        }
      }
    }

    .vs-table {
      border-collapse: separate;
      border-spacing: 0 1.3rem;
      padding: 0 1rem;

      tr {
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, .05);

        td {
          padding: 10px;

          &:first-child {
            border-top-left-radius: .5rem;
            border-bottom-left-radius: .5rem;
          }

          &:last-child {
            border-top-right-radius: .5rem;
            border-bottom-right-radius: .5rem;
          }

          &.img-container {
            // width: 1rem;
            // background: #fff;

            span {
              display: flex;
              justify-content: flex-start;
            }

            .investment-input {
              max-width: 250px;
            }
          }

        }

        td.td-check {
          padding: 20px !important;
        }

        .statement-btn {
          padding: 0.8rem 1rem;
          margin-left: 1rem;
        }
      }
    }

    .vs-table--thead {
      th {
        padding-top: 0;
        padding-bottom: 0;

        .vs-table-text {
          text-transform: uppercase;
          font-weight: 600;
        }
      }

      th.td-check {
        padding: 0 15px !important;
      }

      tr {
        background: none;
        box-shadow: none;
      }
    }

    .vs-table--pagination {
      justify-content: center;
    }
  }
}
</style>
